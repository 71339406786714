export function banks(params, {cancelToken}) {
  return {
    method: 'GET',
    endpoint: `/secure/banks/all`,
    params,
    cancelToken,
  };
}

export function bankList(params, {cancelToken}) {
  return {
    method: 'GET',
    endpoint: `/secure/banks/list`,
    params,
    cancelToken,
  };
}

export function bankWithdrawTopBalance({cancelToken}) {
  return {
    method: 'GET',
    endpoint: `/secure/banks/withdraw/top-balance`,
    cancelToken,
  };
}

export function createBank(payload) {
  return {
    method: 'POST',
    endpoint: `/secure/banks/create`,
    data: payload,
  };
}

export function editBank(payload) {
  const {id} = payload;
  return {
    method: 'PATCH',
    endpoint: `/secure/banks/edit/${id}`,
    data: payload,
  };
}

export function editBankByAccountNo(payload) {
  const {accountNo} = payload;
  return {
    method: 'PATCH',
    endpoint: `/secure/banks/edit/account-no/${accountNo}`,
    data: payload,
  };
}

/**
 *
 * @param {*} payload
 * @param {number} payload.id
 * @param {number[]} payload.visibleLevel // Array number ex, [1,2,3]
 * @returns
 */
export function editBankVisibleLevel(payload) {
  const {id} = payload;
  return {
    method: 'PATCH',
    endpoint: `/secure/banks/edit/visible-level/${id}`,
    data: payload,
  };
}

export function deleteBank(payload) {
  const {id} = payload;
  return {
    method: 'DELETE',
    endpoint: `/secure/banks/delete/${id}`,
  };
}
export function deleteBankByAccountNo(payload) {
  const {accountNo} = payload;
  return {
    method: 'DELETE',
    endpoint: `/secure//banks/delete/account-no/${accountNo}`,
  };
}

export function accountByAccountNo(payload, {cancelToken}) {
  const {accountNo} = payload;
  return {
    method: 'GET',
    endpoint: `/secure/auto/account/account-no/${accountNo}`,
    cancelToken,
  };
}

export function startAuto(payload) {
  return {
    method: 'POST',
    endpoint: `/secure/auto/start`,
    data: payload,
  };
}

export function stopAuto(payload) {
  return {
    method: 'POST',
    endpoint: `/secure/auto/stop`,
    data: payload,
  };
}

export function inProgress({cancelToken}) {
  return {
    method: 'GET',
    endpoint: `/secure/auto/in-progress`,
    cancelToken,
  };
}
