import {get} from 'lodash';
import {useDispatch, useSelector} from 'react-redux';

import {closeDialog, DialogView} from 'store/uiProvider.slice';

import ApproveSlipRequestDialog from 'components/Slip/Dialog/ApproveSlipRequestDialog';
import ConfirmWithdrawDialog from 'components/Withdraw/Request/Dialog/ConfirmWithdrawDialog';
import KbizScanQrCodeForTransfer from 'components/Withdraw/Request/Dialog/KbizScanQrCodeForTransfer';
import KittypayzInquiryStatusDialog from 'components/Withdraw/Request/Dialog/KittypayzInquiryStatusDialog';
import MainDialog from 'components/shared/Dialog/MainDialog';
import ManualSlipRequestDialog from 'components/Slip/Dialog/ManualSlipRequestDialog';
import NotfoundSlipRequestDialog from 'components/Slip/Dialog/NotfoundSlipRequestDialog';
import RejectSlipRequestDialog from 'components/Slip/Dialog/RejectSlipRequestDialog';
import UpdateHomeCustomization from 'components/Customization/Dialog/UpdateHomeCustomization';
import BankVisibleLevelDialog from 'components/Bank/Dialog/BankVisibleLevelDialog';

const UiProvider = () => {
  const dispatch = useDispatch();

  const DialogUI = () => {
    const {displayDialog, dialogView, dialogMaxWidth, dialogClickOutsideToClose} = useSelector(state =>
      get(state, 'uiProvider', {})
    );

    const onClose = () => {
      dispatch(closeDialog());
    };

    return (
      <MainDialog
        open={displayDialog}
        onClose={onClose}
        dialogClickOutsideToClose={dialogClickOutsideToClose}
        maxWidth={dialogMaxWidth}>
        {/* Slip */}
        {DialogView.APPROVE_SLIP_REQUEST === dialogView && <ApproveSlipRequestDialog />}
        {DialogView.REJECT_SLIP_REQUEST === dialogView && <RejectSlipRequestDialog />}
        {DialogView.MANUAL_SLIP_REQUEST === dialogView && <ManualSlipRequestDialog />}
        {DialogView.NOT_FOUND_SLIP_REQUEST === dialogView && <NotfoundSlipRequestDialog />}

        {/* Scan QR Code for Transfer */}
        {DialogView.KBIZ_SCAN_QR_CODE_TRANSFER === dialogView && <KbizScanQrCodeForTransfer />}

        {/* Home Customization */}
        {DialogView.UPDATE_HOME_CONFIGURATION === dialogView && <UpdateHomeCustomization />}

        {/* Withdraw */}
        {DialogView.CONFIRM_WITHDRAW === dialogView && <ConfirmWithdrawDialog />}

        {/* Kittypayz */}
        {DialogView.KITTYPAYZ_INQUIRY_STATUS === dialogView && <KittypayzInquiryStatusDialog />}

        {/* Bank */}
        {DialogView.BANK_VISIBLE_LEVEL === dialogView && <BankVisibleLevelDialog />}
      </MainDialog>
    );
  };

  return (
    <>
      <DialogUI />
    </>
  );
};

export default UiProvider;
