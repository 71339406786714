import {get} from 'lodash';
import {useDispatch, useSelector} from 'react-redux';
import {useEffect, useState} from 'react';

import {CircularProgress} from '@material-ui/core';

import {fetchWithdrawHistory, fetchWithdrawKittypayzInquiryStatus, fetchWithdrawRequest} from 'store/withdraw.slice';

import {SmallButton as Button} from 'components/shared/Button';
import {closeDialog} from 'store/uiProvider.slice';

const KittypayzInquiryStatusDialog = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);

  const withdrawId = useSelector(state => get(state, 'withdraw.withdrawId', ''));

  const [message, setMessage] = useState('');
  const [colors, setColors] = useState('#');

  const fetchCheckStatus = async () => {
    try {
      setLoading(true);
      const result = await dispatch(fetchWithdrawKittypayzInquiryStatus({withdrawId: withdrawId})).unwrap();

      setMessage(result.message);

      switch (result?.data?.status) {
        case 'success':
          setColors('#007500');
          break;
        case 'processing':
          setColors('#FAD02C');
          break;
        case 'failed':
          setColors('#FC2E20');
          break;
        case 'expired':
          setColors('#FD7F20');
          break;
        default:
          break;
      }

      dispatch(fetchWithdrawRequest());
      dispatch(fetchWithdrawHistory());
    } catch (error) {
      setMessage(error.toString());
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCheckStatus();
  }, []);

  if (loading) {
    return (
      <div className="py-6 flex flex-col items-center justify-center gap-4">
        <div className="text-lg font-medium">กำลังตรวจสอบสถานะ..</div>
        <CircularProgress style={{color: 'white'}} />
      </div>
    );
  }

  return (
    <div className="flex flex-col justify-center items-center gap-3 py-6">
      <div className="text-xl font-bold" style={{color: colors}}>
        {message}
      </div>
      <Button style={{color: colors, fontWeight: 'bold'}} onClick={() => dispatch(closeDialog())}>
        ยืนยัน
      </Button>
    </div>
  );
};

export default KittypayzInquiryStatusDialog;
