import {useDispatch, useSelector} from 'react-redux';

import {popup} from 'utils/common.util';

import {DialogView, openDialog} from 'store/uiProvider.slice';
import {setConfirmWithdraw} from 'store/withdraw.slice';

const errorTimestampsKey = 'errorTimestamps';

const useWithdraw = () => {
  const dispatch = useDispatch();

  const {confirmWithdraw} = useSelector(state => state.withdraw);

  let errorTimestamps = localStorage.getItem(errorTimestampsKey);

  const checkAndOpenDialog = () => {
    if (errorTimestamps === null) {
      errorTimestamps = {};
    } else {
      errorTimestamps = JSON.parse(errorTimestamps);
    }

    const withdrawId = confirmWithdraw.withdrawId;

    // 5 minutes = 5 * 60 * 1000
    if (errorTimestamps[withdrawId] && Date.now() - errorTimestamps[withdrawId] < 300000) {
      popup({
        icon: 'info',
        title: 'โปรดรอประมาณ 5 นาทีก่อนลองอีกครั้ง',
      });
      return false;
    }

    return true;
  };

  const openConfirmWithdrawDialog = props => {
    const {fnWithdraw, bankName, id, withdrawId, bankId, data} = props;

    checkAndOpenDialog();

    dispatch(openDialog(DialogView.CONFIRM_WITHDRAW));
    dispatch(setConfirmWithdraw({fnWithdraw, bankName, id, withdrawId, bankId, data}));
  };

  const handleConfirmWithdraw = () => {
    dispatch(confirmWithdraw.fnWithdraw());
  };

  return {
    openConfirmWithdrawDialog,
    handleConfirmWithdraw,
  };
};

export default useWithdraw;
