import api from 'api/api';
import axios from 'axios';
import {
  balance,
  cancelNoRefund,
  cancelWithRefund,
  confirm,
  manual,
  sum,
  withdrawBAY,
  withdrawGSB,
  withdrawHistory,
  withdrawKBANK,
  withdrawKBANKCancelQrCode,
  withdrawKBANKConfirmQrCode,
  withdrawKittypayz,
  withdrawKittypayzInquiryStatus,
  withdrawRequest,
  withdrawSCB,
} from 'containers/Withdraw/apiHelper';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {omit} from 'lodash';

export const fetchBankBalance = createAsyncThunk('withdraw/fetchBalance', async (arg, {signal}) => {
  const source = axios.CancelToken.source();
  signal.addEventListener('abort', () => {
    source.cancel();
  });

  const response = await api(balance(arg, {cancelToken: source.token}));
  return response.data;
});

export const fetchSum = createAsyncThunk('withdraw/fetchSum', async (arg, {signal}) => {
  const source = axios.CancelToken.source();
  signal.addEventListener('abort', () => {
    source.cancel();
  });

  const response = await api(sum(arg, {cancelToken: source.token}));
  return response.data;
});

export const fetchWithdrawRequest = createAsyncThunk('withdraw/fetchRequest', async (arg, {signal}) => {
  const source = axios.CancelToken.source();
  signal.addEventListener('abort', () => {
    source.cancel();
  });

  const response = await api(withdrawRequest({cancelToken: source.token}));
  return response.data;
});

export const fetchWithdrawHistory = createAsyncThunk('withdraw/fetchHistory', async (arg, {signal}) => {
  const source = axios.CancelToken.source();
  signal.addEventListener('abort', () => {
    source.cancel();
  });

  const response = await api(withdrawHistory({cancelToken: source.token}));
  return response.data;
});

export const fetchWithdrawSCB = createAsyncThunk('withdraw/fetchSCB', async arg => {
  const response = await api(withdrawSCB(arg));
  return response.data;
});

export const fetchWithdrawGSB = createAsyncThunk('withdraw/fetchGSB', async arg => {
  const response = await api(withdrawGSB(arg));
  return response.data;
});

export const fetchWithdrawBAY = createAsyncThunk('withdraw/fetchBAY', async arg => {
  const response = await api(withdrawBAY(arg));
  return response.data;
});

export const fetchWithdrawKBANK = createAsyncThunk('withdraw/fetchKBANK', async arg => {
  const response = await api(withdrawKBANK(arg));
  return response.data;
});

export const fetchWithdrawKittypayz = createAsyncThunk('withdraw/fetchKittypayz', async arg => {
  const response = await api(withdrawKittypayz(arg));
  return response.data;
});

export const fetchWithdrawKittypayzInquiryStatus = createAsyncThunk(
  'withdraw/fetchKittypayzInquiryStatus',
  async arg => {
    const response = await api(withdrawKittypayzInquiryStatus(arg));
    return response.data;
  }
);

export const fetchWithdrawKBANKCancelQrCode = createAsyncThunk('withdraw/fetchWithdrawKBANKCancelQrCode', async arg => {
  const response = await api(withdrawKBANKCancelQrCode(arg));
  return response.data;
});

export const fetchWithdrawKBANKConfirmQrCode = createAsyncThunk(
  'withdraw/fetchWithdrawKBANKConfirmQrCode',
  async arg => {
    const response = await api(withdrawKBANKConfirmQrCode(arg));
    return response.data;
  }
);

export const fetchWithdrawConfirmTransaction = createAsyncThunk('withdraw/fetchConfirmTransaction', async arg => {
  const response = await api(confirm(arg));
  return response.data;
});

export const fetchWithdrawManual = createAsyncThunk('withdraw/fetchManual', async arg => {
  const response = await api(manual(arg));
  return response.data;
});

export const fetchWithdrawCancelWithRefund = createAsyncThunk('withdraw/fetchCancelWithRefund', async arg => {
  const response = await api(cancelWithRefund(arg));
  return response.data;
});

export const fetchWithdrawCancelNoRefund = createAsyncThunk('withdraw/fetchCancelNoRefund', async arg => {
  const response = await api(cancelNoRefund(arg));
  return response.data;
});

const extraReducers = {
  [fetchBankBalance.pending]: state => {
    state.balance = {...state.balance, loading: true};
  },
  [fetchBankBalance.fulfilled]: (state, action) => {
    state.balance = {
      ...state.balance,
      [action.payload.data.id]: action.payload.data,
      loading: false,
    };
  },
  [fetchSum.pending]: state => {
    state.sum = {...state.sum, loading: true};
  },
  [fetchSum.fulfilled]: (state, action) => {
    state.sum = {
      ...state.sum,
      [action.payload.data.id]: action.payload.data,
      loading: false,
    };
  },
  [fetchWithdrawRequest.pending]: (state, action) => {
    state.request = {...action.payload, loading: true};
  },
  [fetchWithdrawRequest.fulfilled]: (state, action) => {
    state.request = {...action.payload, loading: false};
  },
  [fetchWithdrawHistory.pending]: (state, action) => {
    state.history = {...action.payload, loading: true};
  },
  [fetchWithdrawHistory.fulfilled]: (state, action) => {
    state.history = {...action.payload, loading: false};
  },
  [fetchWithdrawSCB.pending]: (state, action) => {
    state.operation = {
      [action.meta.arg.withdrawId]: {...action.payload},
      loading: true,
    };
  },
  [fetchWithdrawSCB.fulfilled]: (state, action) => {
    state.operation = {
      ...omit(state.operation, [action.payload.data.withdrawId]),
      loading: false,
    };
  },
  [fetchWithdrawBAY.pending]: (state, action) => {
    state.operation = {
      [action.meta.arg.withdrawId]: {...action.payload},
      loading: true,
    };
  },
  [fetchWithdrawBAY.fulfilled]: (state, action) => {
    state.operation = {
      ...omit(state.operation, [action.payload.data.withdrawId]),
      loading: false,
    };
  },
  [fetchWithdrawKBANK.pending]: (state, action) => {
    state.operation = {
      [action.meta.arg.withdrawId]: {...action.payload},
      loading: true,
    };
  },
  [fetchWithdrawKBANK.fulfilled]: (state, action) => {
    state.operation = {
      ...omit(state.operation, [action.payload.data.withdrawId]),
      loading: false,
    };
  },
  [fetchWithdrawKBANKCancelQrCode.pending]: (state, action) => {
    state.operation = {
      [action.meta.arg.withdrawId]: {...action.payload},
      loading: true,
    };
  },
  [fetchWithdrawKBANKCancelQrCode.fulfilled]: (state, action) => {
    state.operation = {
      ...omit(state.operation, [action.payload.data.withdrawId]),
      loading: false,
    };
  },
  [fetchWithdrawKBANKConfirmQrCode.pending]: (state, action) => {
    state.operation = {
      [action.meta.arg.withdrawId]: {...action.payload},
      loading: true,
    };
  },
  [fetchWithdrawKBANKConfirmQrCode.fulfilled]: (state, action) => {
    state.operation = {
      ...omit(state.operation, [action.payload.data.withdrawId]),
      loading: false,
    };
  },
  [fetchWithdrawGSB.pending]: (state, action) => {
    state.operation = {
      [action.meta.arg.withdrawId]: {...action.payload},
      loading: true,
    };
  },
  [fetchWithdrawGSB.fulfilled]: (state, action) => {
    state.operation = {
      ...omit(state.operation, [action.payload.data.withdrawId]),
      loading: false,
    };
  },
};

const initialState = {
  request: {},
  history: {},
  balance: {},
  sum: {},
  operation: {},
  scanQrCode: {},
  confirmWithdraw: {
    fnWithdraw: () => {},
    bankName: null, // SCB, KBANK, GSB, KITTYPAYZ
    id: null, // ID's bank
    withdrawId: null, // withdraw request id
    bankId: null, // bank account no
    data: {
      uuid: '',
      bankid: null,
      amount: null,
      latestDeposit: null,
      note: null,
      ref1: '',
      ref2: '',
      status: 1,
      user: {
        uuid: '',
        name: '',
        phone: '',
        user_bank: {
          bankId: '',
          bankName: '',
        },
        user_pass: {
          username: '',
        },
      },
      userid: '',
      createdAt: null,
      updatedAt: null,
    },
  },
  withdrawId: null,
};

const name = 'withdraw';

export const slice = createSlice({
  name,
  initialState,
  reducers: {
    reset: () => initialState,
    setRequestList: (state, action) => {
      state.request.data = action.payload;
    },
    setHistoryList: (state, action) => {
      state.history.data = action.payload;
    },
    setWithdrawRequestToScanQrCode: (state, action) => {
      const {data} = action.payload;
      state.scanQrCode = data;
    },

    /**
     * Set confirm withdraw
     * @param {*} action
     * @param {Function} action.payload.fnWithdraw function to withdraw
     * @param {string} action.payload.bankName bank name such as SCB KBANK KITTYPAYZ
     * @param {string} action.payload.id ID's bank
     * @param {string} action.payload.withdrawId withdraw request id
     * @param {string} action.payload.bankId bank account no
     */
    setConfirmWithdraw: (state, action) => {
      state.confirmWithdraw = action.payload;
    },

    /**
     *  withdraw id
     * @param {*} state
     * @param {*} action
     * @param {string} action.payload.withdrawId withdraw id
     */
    setWithdrawId: (state, action) => {
      state.withdrawId = action.payload.withdrawId;
    },
  },
  extraReducers,
});

// Action creators are generated for each case reducer function
export const {reset, setWithdrawRequestToScanQrCode, setConfirmWithdraw, setWithdrawId} = slice.actions;

export default slice.reducer;
