import {closeDialog} from 'store/uiProvider.slice';
import {get, isEmpty} from 'lodash';
import {useDispatch, useSelector} from 'react-redux';
import {useEffect, useState} from 'react';

import Image from 'components/shared/Image';
import {Button} from '@material-ui/core';
import {popup} from 'utils/common.util';
import {useStyles} from 'containers/Setting/Bank/useStyles';

import {fetchBankAll, fetchEditBankVisibleLevel} from 'store/bank.slice';

const LEVEL_CONFIG = [
  {name: 'Silver', value: 1, image: '01'},
  {name: 'Gold', value: 2, image: '02'},
  {name: 'Platinum', value: 3, image: '03'},
  {name: 'Diamond', value: 4, image: '04'},
  {name: 'VIP', value: 5, image: '05'},
];

const BankVisibleLevelDialog = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const bank = useSelector(state => get(state, 'bank.bank', {}));

  const filter = useSelector(state => get(state, 'bank.filterBank', {}));

  const [selectedLevels, setSelectedLevels] = useState(new Set());

  const handleToggleLevel = value => {
    setSelectedLevels(prev => {
      const newSet = new Set(prev);
      if (newSet.has(value)) {
        newSet.delete(value);
      } else {
        newSet.add(value);
      }
      return newSet;
    });
  };

  const handleSubmit = async () => {
    const levels = Array.from(selectedLevels);
    try {
      const data = await dispatch(fetchEditBankVisibleLevel({id: bank.id, visibleLevel: levels})).unwrap();
      await dispatch(fetchBankAll(filter));
      popup({icon: 'success', title: data?.message});
      dispatch(closeDialog());
      return;
    } catch (err) {
      dispatch(closeDialog());
      const message = err?.data?.message || err.message;
      return popup({icon: 'error', title: message});
    }
  };

  useEffect(() => {
    if (bank.visibleLevel) {
      setSelectedLevels(new Set(bank.visibleLevel));
    }
  }, [bank.visibleLevel]);

  return (
    <div className=" rounded-lg overflow-hidden">
      {/* Title Bar */}
      <div className=" px-6 py-4 border-b border-gray-700">
        <h2 className="text-white text-xl font-semibold">ระดับที่สามารถมองเห็น</h2>
      </div>

      {/* Content */}
      <div className="p-6">
        <div className="grid grid-cols-2 md:grid-cols-3 gap-3">
          {LEVEL_CONFIG.map(({name, value, image}) => (
            <div
              key={value}
              className="bg-gray-800 rounded-lg p-4 text-center hover:bg-gray-750 transition-colors cursor-pointer"
              onClick={() => handleToggleLevel(value)}>
              <div className="relative flex justify-center items-center">
                <Image
                  src={`/assets/images/user/500x500-${image}.png`}
                  width={120}
                  height={120}
                  alt={name}
                  draggable="false"
                  className={`rounded-lg transition-all duration-200 ${
                    !selectedLevels.has(value) ? 'opacity-30 scale-95' : 'opacity-100 scale-100'
                  }`}
                />
                {selectedLevels.has(value) && (
                  <div className="absolute top-0 right-0 -mt-2 -mr-2 bg-green-500 rounded-full p-1">
                    <svg className="w-4 h-4 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                  </div>
                )}
              </div>
              <div className="text-gray-300 text-sm font-medium">{name}</div>
            </div>
          ))}
        </div>
      </div>

      {/* Footer */}
      <div className=" px-6 py-4 border-t border-gray-700 flex justify-end space-x-4">
        <Button className={classes.stopBtn} onClick={() => dispatch(closeDialog())}>
          ยกเลิก
        </Button>
        <Button className={classes.activeBtn} onClick={handleSubmit}>
          ยืนยัน
        </Button>
      </div>
    </div>
  );
};

export default BankVisibleLevelDialog;
