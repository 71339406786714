import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  paper: {
    backgroundColor: theme.palette.background.black,
  },
  startBtn: {
    textTransform: 'none',
    backgroundColor: '#4169E1',
    color: 'white',
    '&:disabled': {
      color: '#fff',
      background: '#444444',
    },
  },
  activeBtn: {
    background: '#20C55E',
    color: 'white',
  },
  stopBtn: {
    textTransform: 'none',
    backgroundColor: '#9A070B',
    color: 'white',
  },
  deleteBtn: {
    backgroundColor: 'red',
    color: 'white',
  },
  mainBtn: {
    backgroundColor: theme.palette.background.mainBtn,
    color: 'white',
  },
  mainDelete: {
    backgroundColor: 'Red',
    color: 'white',
  },
  tableRowColor: {
    backgroundColor: theme.palette.background.grey1,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '25ch',
    '& label.Mui-focused': {
      color: 'white',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.background.tableLine,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.background.tableLine,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.background.tableLine,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.background.tableLine,
      },
    },
  },
  textColor: {
    color: 'white',
  },
}));
