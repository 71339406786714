import api from 'api/api';
import axios from 'axios';
import {
  accountByAccountNo,
  bankList,
  banks,
  bankWithdrawTopBalance,
  createBank,
  deleteBank,
  deleteBankByAccountNo,
  editBank,
  editBankByAccountNo,
  editBankVisibleLevel,
  inProgress,
  startAuto,
  stopAuto,
} from 'containers/Setting/Bank/apiHelper';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

export const fetchBankAll = createAsyncThunk('bank/fetchAll', async (arg, {signal}) => {
  const source = axios.CancelToken.source();
  signal.addEventListener('abort', () => {
    source.cancel();
  });
  const response = await api(banks(arg, {cancelToken: source.token}));
  return response.data;
});

export const fetchBankWithdrawTopBalance = createAsyncThunk(
  'bank/fetchBankWithdrawTopBalance',
  async (arg, {signal}) => {
    const source = axios.CancelToken.source();
    signal.addEventListener('abort', () => {
      source.cancel();
    });
    const response = await api(bankWithdrawTopBalance({cancelToken: source.token}));
    return response.data;
  }
);

export const fetchBankList = createAsyncThunk('bank/fetchBankList', async (arg, {signal}) => {
  const source = axios.CancelToken.source();
  signal.addEventListener('abort', () => {
    source.cancel();
  });
  const response = await api(bankList(arg, {cancelToken: source.token}));
  return response.data;
});

export const fetchCreateBank = createAsyncThunk('bank/fetchCreateBank', async arg => {
  const response = await api(createBank(arg));
  return response.data;
});

export const fetchEditBank = createAsyncThunk('bank/fetchEditBank', async arg => {
  const response = await api(editBank(arg));
  return response.data;
});

export const fetchEditBankByAccountNo = createAsyncThunk('bank/fetchEditBankByAccountNo', async arg => {
  const response = await api(editBankByAccountNo(arg));
  return response.data;
});

export const fetchEditBankVisibleLevel = createAsyncThunk('bank/fetchEditBankVisibleLevel', async (arg, thunkAPI) => {
  try {
    const response = await api(editBankVisibleLevel(arg));
    return response.data;
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const fetchDeleteBank = createAsyncThunk('bank/fetchDeleteBank', async arg => {
  const response = await api(deleteBank(arg));
  return response.data;
});

export const fetchDeleteByAccountNo = createAsyncThunk('bank/fetchDeleteByAccountNo', async arg => {
  const response = await api(deleteBankByAccountNo(arg));
  return response.data;
});

export const fetchAccountByAccountNo = createAsyncThunk('bank/fetchAccountByAccountNo', async (arg, {signal}) => {
  const source = axios.CancelToken.source();
  signal.addEventListener('abort', () => {
    source.cancel();
  });
  const response = await api(accountByAccountNo(arg, {cancelToken: source.token}));
  return response.data;
});

export const fetchStartAuto = createAsyncThunk('bank/fetchStartAuto', async arg => {
  const response = await api(startAuto(arg));
  return response.data;
});

export const fetchStopAuto = createAsyncThunk('bank/fetchStopAuto', async arg => {
  const response = await api(stopAuto(arg));
  return response.data;
});

export const fetchInProgress = createAsyncThunk('bank/fetchInProgress', async (arg, {signal}) => {
  const source = axios.CancelToken.source();
  signal.addEventListener('abort', () => {
    source.cancel();
  });
  const response = await api(inProgress({cancelToken: source.token}));
  return response.data;
});

const extraReducers = {
  [fetchBankAll.pending]: (state, action) => {
    state.all = {...action.payload, loading: true};
  },
  [fetchBankAll.fulfilled]: (state, action) => {
    state.all = {...action.payload, loading: false};
  },
  [fetchBankList.pending]: (state, action) => {
    state.all = {...action.payload, loading: true};
  },
  [fetchBankList.fulfilled]: (state, action) => {
    state.all = {...action.payload, loading: false};
  },
  [fetchBankWithdrawTopBalance.pending]: (state, action) => {
    state.withdrawTopBalance = {...action.payload, loading: true};
  },
  [fetchBankWithdrawTopBalance.fulfilled]: (state, action) => {
    state.withdrawTopBalance = {...action.payload, loading: false};
  },
  [fetchInProgress.pending]: (state, action) => {
    state.inProgress = {...action.payload, loading: true};
  },
  [fetchInProgress.fulfilled]: (state, action) => {
    state.inProgress = {...action.payload, loading: false};
  },
  [fetchAccountByAccountNo.pending]: (state, action) => {
    state.account = {...action.payload, loading: true};
  },
  [fetchAccountByAccountNo.fulfilled]: (state, action) => {
    state.account = {...action.payload, loading: false};
  },
};

const initialState = {
  all: {},
  inProgress: {},
  account: {},
  withdrawTopBalance: {},
  bank: {},
  filterBank: {},
};

const name = 'bank';

export const slice = createSlice({
  name,
  initialState,
  reducers: {
    setBank: (state, action) => {
      state.bank = {...action.payload};
    },
    setFilterBank: (state, action) => {
      state.filterBank = {...action.payload};
    },
    reset: () => initialState,
  },
  extraReducers,
});

// Action creators are generated for each case reducer function
export const {reset, setBank, setFilterBank} = slice.actions;

export default slice.reducer;
