import {useSelector} from 'react-redux';

import useWithdraw from 'hooks/useWithdraw';

const ConfirmWithdrawDialog = () => {
  const {handleConfirmWithdraw} = useWithdraw();

  const {confirmWithdraw} = useSelector(state => state.withdraw);

  const {bankName, id, withdrawId, bankId, data} = confirmWithdraw;

  return (
    <div className="flex justify-center items-center">
      <div>{bankName}</div>
      <div>{id}</div>
      <div>{withdrawId}</div>
      <div>{bankId}</div>
      <button onClick={() => handleConfirmWithdraw()}>CLick</button>
    </div>
  );
};

export default ConfirmWithdrawDialog;
